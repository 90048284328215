import React, { useEffect } from 'react';

import { useTranslation } from 'react-i18next';
import { logOutAsync } from '../../redux/slices/userSlice';
import { useDispatch, useSelector } from 'react-redux';
import useRoutes, { RoutesPaths } from '../../hooks/useRoutes';

import Icon from '../../bit/components/icon';
import {
  ThemeEnum,
  setThemeAsync,
  selectSettings,
  setSidebarState
} from '../../redux/slices/settingsSlice';
import Typography from '../Typography';

import webIcon from '../../images/globe-white.svg';
import telegramIcon from '../../images/telegram.svg';
import instagramIcon from '../../images/instagram.svg';
import {
  SidebarHeader,
  SidebarList,
  Socials,
  StyledFooter,
  StyledIconWrapper,
  StyledLanguageSelect,
  StyledLogo,
  StyledSidebar,
  StyledSidebarButton,
  StyledSidebarItem,
  StyledSidebarTitle,
  StyledUserInfo,
  Support,
  SupportIcon,
  SupportLink,
  ThemeSwitcher
} from './AppSidebarStyled';

const AppSidebar = () => {
  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { routes } = useRoutes();

  const { isOpenOnMobile, theme } = useSelector(selectSettings);

  useEffect(() => {
    if (isOpenOnMobile) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = '';
    }
  }, [isOpenOnMobile]);

  const handleLogOut = React.useCallback(() => dispatch(logOutAsync()), [
    dispatch
  ]);

  const onChangeTheme = React.useCallback(() => {
    const value = theme === ThemeEnum.dark ? ThemeEnum.light : ThemeEnum.dark;
    dispatch(setThemeAsync(value));
  }, [theme, dispatch]);

  const handleClick = React.useCallback(() => {
    dispatch(setSidebarState(false));
  }, [dispatch]);

  return (
    <StyledSidebar isOpenOnMobile={isOpenOnMobile}>
      <div>
        <SidebarHeader>
          <StyledLogo />
          <StyledSidebarButton isCloseButton />
        </SidebarHeader>
        <StyledLanguageSelect />
        <StyledUserInfo onLogOut={handleLogOut} />
        <SidebarList>
          {Object.keys(routes).map((routeKey) => {
            const route = routes[routeKey as RoutesPaths];
            const path = Array.isArray(route.path) ? route.path[0] : route.path;

            if (!route.title || !route.icon) {
              return null;
            }

            return (
              <StyledSidebarItem
                onClick={handleClick}
                key={routeKey}
                path={path}
                exact
              >
                <StyledIconWrapper>
                  <Icon
                    type='stroke'
                    size={18}
                    src={route.icon}
                    color='inherit'
                  />
                </StyledIconWrapper>
                <StyledSidebarTitle
                  size={16}
                  variant='subtitle2'
                  color='inherit'
                >
                  {route.title}
                </StyledSidebarTitle>
              </StyledSidebarItem>
            );
          })}
        </SidebarList>
      </div>

      <StyledFooter>
        <ThemeSwitcher
          isRow
          active={theme === ThemeEnum.dark}
          onChange={onChangeTheme}
          label={t('sidebar.darkTheme')}
        />
        <Support>
          <Typography variant='subtitle2' color='inherit'>
            {t('common.support')}
          </Typography>

          <SupportLink href='https://telegram.me/peppersup' target='_blank'>
            <SupportIcon src={telegramIcon} />
            <Typography variant='subtitle2' color='inherit'>
              @peppersup
            </Typography>
          </SupportLink>
        </Support>
      </StyledFooter>

      <StyledFooter>
        <Support>
          <Typography variant='subtitle2' color='inherit'>
            {t('common.solutions')}
          </Typography>

          <SupportLink href='https://crmpp.pepper.partners' target='_blank'>
            <SupportIcon src={webIcon} />
            <Typography variant='subtitle2' color='inherit'>
              Pepper CRM
            </Typography>
          </SupportLink>

          <SupportLink href='https://t.me/+XL-z1i80R4g0NDUy' target='_blank'>
            <SupportIcon src={telegramIcon} />
            <Typography variant='subtitle2' color='inherit'>
              Pepper Shop
            </Typography>
          </SupportLink>
        </Support>
      </StyledFooter>

      <Socials>
        <Typography variant='subtitle2' color='inherit'>
          {t('common.society')}
        </Typography>

        <SupportLink
          href='https://t.me/joinchat/UF486GaNNHmEwMbB'
          target='_blank'
        >
          <SupportIcon src={telegramIcon} />
          <Typography variant='subtitle2' color='inherit'>
            {t('sidebar.blog')}
          </Typography>
        </SupportLink>

        <SupportLink
          href='https://www.instagram.com/pepper.prtnrs/'
          target='_blank'
        >
          <SupportIcon src={instagramIcon} />
          <Typography variant='subtitle2' color='inherit'>
            instpepper
          </Typography>
        </SupportLink>
      </Socials>
    </StyledSidebar>
  );
};

export default AppSidebar;
