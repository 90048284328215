import styled from 'styled-components';

import Sidebar from '../../bit/components/sidebar';
import SidebarItem from '../../bit/components/sidebar-item';
import { color, transition } from '../../bit/components/utils/utils';
import LanguageSelect from '../LanguageSelect';

import Logo from '../Logo';
import UserInfo from '../UserInfo';
import Switcher from '../Switcher';
import SidebarTitle from '../SidebarTitle';
import media from '../../resources/media';
import SidebarButton from '../SidebarButton';

export const StyledSidebar = styled(Sidebar)<{ isOpenOnMobile?: boolean }>`
  overflow-y: auto;
  z-index: 1000;
  display: flex;
  padding: 32px 24px;
  flex-direction: column;
  box-shadow: ${(props) => props.theme.components.sidebar.shadow};

  @media ${media.tablet} {
    display: ${(props) => (props.isOpenOnMobile ? 'flex' : 'none')};
    width: 100%;
  }
`;

export const SidebarHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 20px;
`;

export const StyledLogo = styled(Logo)`
  width: 250px;
`;

export const StyledSidebarButton = styled(SidebarButton)`
  display: none;

  @media ${media.tablet} {
    display: inline-flex;
  }
`;

export const StyledLanguageSelect = styled(LanguageSelect)`
  margin-bottom: 20px;
`;

export const StyledUserInfo = styled(UserInfo)`
  margin-bottom: 20px;
`;

export const StyledIconWrapper = styled.div`
  width: 40px;
  min-width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;

  margin-right: 16px;
`;

export const ThemeSwitcher = styled(Switcher)`
  margin-bottom: 36px;
`;

export const StyledFooter = styled.div`
  flex: none;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
  color: ${(props) => props.theme.components.sidebar.color};
`;

export const Support = styled.div`
  display: inline-flex;
  flex-direction: column;
`;

export const Socials = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: auto;
  padding-top: 36px;
  color: ${(props) => props.theme.components.sidebar.color};
`;

export const SupportLink = styled.a`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
  margin-top: 16px;
  cursor: pointer;
  user-select: none;
  color: inherit;
`;

export const SupportIcon = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 10px;
`;

export const SidebarList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledSidebarTitle = styled(SidebarTitle)`
  text-transform: uppercase;
  line-height: 22px;
`;

export const StyledSidebarItem = styled(SidebarItem)`
  display: inline-flex;
  align-items: center;
  color: ${(props) => props.theme.components.sidebar.color};
  ${transition({ name: 'background-color' })};

  svg {
    ${transition({ name: 'all' })};
  }

  height: 52px;
  border-radius: 0;

  & + & {
    margin-top: 16px;
  }

  &.active {
    color: ${color('primary.main')};
  }
`;
