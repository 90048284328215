import React, { useMemo } from 'react';
import Page from '../../components/Page';
import Header from '../../components/Header';
import { useTranslation } from 'react-i18next';
import Grid from '../../components/Grid';
import Block from '../../components/Block';
import Input from '../../components/Input';
import styled from 'styled-components';
import Button from '../../bit/components/button';
import { useSelector } from 'react-redux';
import useModalState from '../../hooks/useModalState';
import PasswordRecoveryModal from '../../modals/PasswordRecoveryModal';
import { selectUser } from '../../redux/slices/userSlice';
import InputCopy from '../../components/InputCopy';
import { AuthState } from '../../types';
import useWithTelegram from '../../hooks/useWithTelegram';
import Typography from '../../components/Typography';
import { ThemeEnum, selectSettings } from '../../redux/slices/settingsSlice';

const StyledInput = styled(Input)`
  & + & {
    margin-top: 32px;
  }
`;

const StyledInputCopy = styled(InputCopy)`
  margin-top: 32px;
`;

const StyledBlock = styled(Block)`
  width: 100%;
  max-width: 510px;
`;

const ChangePasswordButton: typeof Button = styled(Button)`
  margin-top: 32px;
`;

const StyledTypography = styled(Typography)<{
  isDark: boolean;
}>`
  margin-bottom: 8px;

  color: ${(props) => {
    return props.isDark
      ? props.theme.palette.text.first
      : props.theme.palette.text.third;
  }};
`;

const ProfilePage = () => {
  const { t } = useTranslation();
  const { info } = useSelector(selectUser);
  const { theme } = useSelector(selectSettings);
  const { withTelegramByUserLang } = useWithTelegram();
  const { open, closeModal, openModal } = useModalState();

  const referralLink = useMemo(() => {
    let link = window.location.origin;

    link += `/${AuthState.register}`;

    if (info?.company.ref) {
      link += `?ref=${info?.company.ref}`;
    }

    return link;
  }, [info]);

  const messengerInputs = useMemo(() => {
    if (info) {
      const { messengers } = info;

      if (withTelegramByUserLang) {
        return (
          <StyledInput
            readOnly
            label={t('common.telegram')}
            value={messengers.telegram}
          />
        );
      }

      return (
        <>
          <StyledInput
            readOnly
            label={t('common.whatsapp')}
            value={messengers.whatsapp}
          />
          <StyledInput
            readOnly
            label={t('common.skype')}
            value={messengers.skype}
          />
          <StyledInput
            readOnly
            label={t('common.wechat')}
            value={messengers.wechat}
          />
        </>
      );
    }
    return null;
  }, [info, t, withTelegramByUserLang]);

  return (
    <Page>
      <Header title={t('sidebar.profile')} />
      <Grid style={{ paddingBottom: 0 }}>
        <StyledBlock style={{ marginBottom: 0 }}>
          <StyledTypography
            variant='subtitle2'
            isDark={theme === ThemeEnum.dark}
          >
            {t('profile.companyId')} {info?.company.scaleoId}
          </StyledTypography>
          <StyledTypography
            variant='subtitle2'
            style={{ marginBottom: 0 }}
            isDark={theme === ThemeEnum.dark}
          >
            {t('profile.userId')} {info?.id}
          </StyledTypography>
        </StyledBlock>
      </Grid>

      <Grid>
        <StyledBlock>
          <StyledInput readOnly label='Email' value={info?.email} />
          {messengerInputs}
          <StyledInputCopy
            rows={5}
            text={referralLink}
            label={t('common.referralLink')}
            multiline
          />
          <ChangePasswordButton
            onClick={openModal}
            variant='outlined'
            size='middle'
          >
            {t('common.changePassword')}
          </ChangePasswordButton>
        </StyledBlock>
      </Grid>
      <PasswordRecoveryModal open={open} onClose={closeModal} />
    </Page>
  );
};

export default ProfilePage;
