import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { getLanguage } from '../../resources/functions';

import en from './en.json';
import ru from './ru.json';
import ua from './ua.json';
import { DEFAULT_LANGUAGE } from '../../resources/constants';

export const initI18n = () => {
  i18n.use(initReactI18next).init({
    fallbackLng: DEFAULT_LANGUAGE,
    resources: { ru, en, ua },
    lng: getLanguage(DEFAULT_LANGUAGE),

    interpolation: {
      escapeValue: false
    }
  });
};

export default i18n;
