import React, { useCallback, useMemo } from 'react';
import styled from 'styled-components';
import { LanguageSelectProps } from './interface';
import Dropdown, {
  DropdownOnChange,
  RenderControlProps,
  RenderOptionProps
} from '../dropdown';
import Typography from '../typography';
import VVIcon from '../icon';

export const LanguageSelectDropdown = styled(Dropdown)<{ isShort?: boolean }>`
  align-self: flex-start;
  width: ${(props) => (props.isShort ? '93px' : 'auto')};
  height: 28px;
  text-transform: capitalize;

  .Dropdown__control {
    border: none;
  }
`;

export const LanguageSelectOption = styled.div`
  span {
    margin-left: 10px;
    text-transform: capitalize;
  }
`;

function LanguageSelect<T extends string>(props: LanguageSelectProps<T>) {
  const { className, isShort, options, value, onChange } = props;

  const handleChange = useCallback<DropdownOnChange>(
    (nextValue) => {
      if (onChange) {
        onChange(nextValue as T);
      }
    },
    [onChange]
  );

  const icon = useMemo(() => {
    return options.find((option) => option.value === value)?.icon;
  }, [options, value]);

  const renderControl = useCallback(
    (controlProps: RenderControlProps) => {
      const {
        className: controlClassName,
        Icon: Chevron,
        onClick,
        label
      } = controlProps;

      return (
        <div className={controlClassName} onClick={onClick}>
          {icon && <VVIcon color='initial' size={16} src={icon} />}
          <Typography size={14} variant='subtitle3'>
            {label}
          </Typography>
          {Chevron}
        </div>
      );
    },
    [icon]
  );

  const renderOption = useCallback((optionProps: RenderOptionProps) => {
    const {
      className: optionClassName,
      key,
      onClick,
      option,
      style
    } = optionProps;

    return (
      <LanguageSelectOption
        className={optionClassName}
        key={key}
        style={style}
        onClick={onClick}
      >
        {option.icon && <VVIcon color='initial' size={16} src={option.icon} />}
        <Typography color='main' size={14} variant='subtitle3'>
          {option.name}
        </Typography>
      </LanguageSelectOption>
    );
  }, []);

  return (
    <LanguageSelectDropdown
      className={className}
      isShort={isShort}
      options={options}
      renderControl={renderControl}
      renderOption={renderOption}
      value={value}
      onChange={handleChange}
    />
  );
}

export default LanguageSelect;
