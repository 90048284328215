import { withDefault, NumberParam } from 'use-query-params';
import { DropdownOption } from '../components/Dropdown';
import { Languages } from '../types';

export const STORE_USER_INFO = 'STORE_USER_INFO';
export const STORE_USER_TOKEN = 'STORE_USER_TOKEN';
export const STORE_THEME = 'STORE_THEME';
export const STORE_COMPANY_INFO = 'STORE_COMPANY_INFO';
export const STORE_LANGUAGE = 'STORE_LANGUAGE';

export const paperAppsId = 'defoftuartd';
export const paperAppsPush = '&push=pepper';
export const pepperPartners = 'pepperpartners';

export const DEFAULT_LANGUAGE = Languages.en;
export const LANGUAGE_NAMES = {
  [Languages.ru]: 'Русский',
  [Languages.en]: 'English',
  [Languages.ua]: 'Українська'
};

//TODO удалить, как разберутся с разрешениями на бэке
export const allowedSections = [
  'apps',
  'billing',
  'employees',
  'main',
  'offers',
  'reports',
  'support',
  'managerial'
];

export const MANAGERIAL_PAGE_HEAD = 'MANAGERIAL_PAGE_HEAD';
export const MANAGERIAL_PAGE_DEBOUNCE = 150;

export const limitPageOptions: DropdownOption[] = [
  { value: 10, name: 10 },
  { value: 25, name: 25 },
  { value: 50, name: 50 },
  { value: 100, name: 100 }
];

export const DEFAULT_LIMIT = Number(limitPageOptions[0].value);

export const DEFAULT_PAGINATION_PARAMS = {
  page: withDefault(NumberParam, 1),
  limit: withDefault(NumberParam, DEFAULT_LIMIT)
};

export const DEFAULT_PAGINATION = {
  page: 1,
  pages: 1,
  limit: DEFAULT_LIMIT,
  total: DEFAULT_LIMIT
};

export const DEFAULT_DATA_WITH_PAGINATION = {
  data: [],
  meta: DEFAULT_PAGINATION
};
