import LangSelect, {
  icons,
  LanguageSelectProps
} from '../../bit/components/language-select';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Languages } from '../../types';
import { Props } from './interface';
import { setLocalStorage } from '../../resources/functions';
import { LANGUAGE_NAMES, STORE_LANGUAGE } from '../../resources/constants';

const LanguageSelect = ({ isShort, className }: Props) => {
  const { i18n } = useTranslation();

  const options = useMemo(
    (): LanguageSelectProps<Languages>['options'] =>
      Object.values(Languages).map((language) => ({
        value: language,
        name: isShort ? language : LANGUAGE_NAMES[language],
        icon: icons[language]
      })),
    [isShort]
  );

  const handleChange = useCallback(
    (nextLanguage: Languages) => {
      i18n.changeLanguage(nextLanguage);
      setLocalStorage(STORE_LANGUAGE, nextLanguage);
    },
    [i18n]
  );

  return (
    <LangSelect
      className={className}
      isShort={isShort}
      value={i18n.language as Languages}
      onChange={handleChange}
      options={options}
    />
  );
};

export default LanguageSelect;
